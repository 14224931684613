<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    style="background: rgba(0, 0, 0, 0)"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    :style="{ 'font-family': settings.typography }"
  >
    <q-card class="q-pa-md" style="max-width: 500px">
      <CloseButton />

      <q-form @submit.prevent="changePassword" class="q-gutter-md">
        <!-- <div align="center">
          <img class="q-ma-lg" :src="settings.logo" />
        </div> -->

        <q-list>
          <q-item class="q-ma-none q-pa-none">
            <q-item-section>
              <q-item-label class="text-h6 text-left">{{
                $t("Login.change-password")
              }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none q-mb-md">
            <q-item-section>
              <q-item-label
                class="text-subtitle2 text-left q-ml-sm q-mb-md q-mr-md q-pa-none"
                >{{ $t("Login.current-password") }}</q-item-label
              >
              <q-input
                filled
                v-model="password.current_password"
                :type="isPwd ? 'password' : 'text'"
                class="q-ml-sm q-mr-sm q-mb-md"
                lazy-rules
                autocomplete="on"
                required
              >
                <template v-slot:append>
                  <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                  />
                </template>
              </q-input>
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none q-mb-md">
            <q-item-section>
              <q-item-label
                class="text-subtitle2 text-left q-ml-sm q-mb-md q-mr-md q-pa-none"
                >{{ $t("Login.new-password") }}</q-item-label
              >
              <q-input
                filled
                v-model="password.new_password"
                :type="isPwd1 ? 'password' : 'text'"
                class="q-ml-sm q-mr-sm"
                :rules="[
                  (val) =>
                    (val && /\d/.test(val) && val.length >= 8) ||
                    $t('Login.SignUp.min-password'),
                ]"
                autocomplete="on"
              >
                <template v-slot:append>
                  <q-icon
                    :name="isPwd1 ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd1 = !isPwd1"
                  />
                </template>
              </q-input>
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none q-mb-md">
            <q-item-section>
              <q-item-label
                class="text-subtitle2 text-left q-ml-sm q-mb-md q-mr-md q-pa-none"
                >{{ $t("Login.confirm-new-password") }}</q-item-label
              >
              <q-input
                filled
                v-model="password.new_password_confirmation"
                :type="isPwd2 ? 'password' : 'text'"
                class="q-ml-sm q-mr-sm"
                :rules="[
                  (val) =>
                    (val && /\d/.test(val) && val.length >= 8) ||
                    $t('Login.SignUp.min-password'),
                ]"
                autocomplete="on"
              >
                <template v-slot:append>
                  <q-icon
                    :name="isPwd2 ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd2 = !isPwd2"
                  />
                </template>
              </q-input>
            </q-item-section>
          </q-item>
        </q-list>

        <div class="row justify-center">
          <q-btn
            class="button-blue"
            :label="$t('Login.change-password')"
            type="submit"
            :style="{ background: settings.color_primary }"
          >
          </q-btn>
        </div>
      </q-form>
    </q-card>

    <!-- Dialog espera -->
    <q-dialog persistent v-model="loading">
      <q-card :style="{ 'font-family': settings.typography }">
        <q-card-section class="text-center">
          <div class="text-body1 q-pb-md">{{ $t("General.wait") }}</div>
          <q-spinner size="40px" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- Dialog problema con cambio de contraseña -->
    <q-dialog persistent v-model="wrongEmail">
      <q-card :style="{ 'font-family': settings.typography }">
        <q-card-actions align="center">
          <q-btn icon="close" color="red" size="25px" flat />
        </q-card-actions>
        <q-card-section>
          <div class="text-h6 text-center">
            {{ $t("General.problem") }}
          </div>
          <q-item-label class="text-center q-mt-md">{{
            $t("Login.problem-password")
          }}</q-item-label>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            class="btn-home"
            :label="$t('Login.try-again')"
            :style="{ 'background-color': settings.color_accent }"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Dialog éxito con cambio de contraseña -->
    <q-dialog persistent v-model="emailReceived">
      <q-card :style="{ 'font-family': settings.typography }">
        <q-card-actions align="center">
          <q-btn icon="done" color="green" size="25px" flat />
        </q-card-actions>
        <q-card-section>
          <div class="text-h6 text-center">
            {{ $t("Login.success-password") }}
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            class="btn-home"
            label="Seguir navegando"
            :style="{ 'background-color': settings.color_accent }"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>
<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";
import { mapState } from "vuex";
import axios from "../../../../../services/Api";

export default {
  mixins: [settings],

  components: {
    CloseButton: defineAsyncComponent(() =>
      import("../../../../shared/buttons/CloseButton.vue")
    ),
  },
  props: {
    dialog: {
      default: false,
    },
  },
  data() {
    return {
      password: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      loading: false,
      wrongEmail: false,
      emailReceived: false,
    };
  },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
      isPwd1: ref(true),
      isPwd2: ref(true),
      isPwd: ref(true),
      dense: ref(""),
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    async changePassword() {
      this.loading = true;

      const body = new FormData();
      body.append("email", this.user.email);
      body.append("password", this.password.current_password);
      body.append("new_password", this.password.new_password);
      body.append(
        "new_password_confirmation",
        this.password.new_password_confirmation
      );

      await axios
        .post("front-catalog/change-password", body)
        .then(() => {
          this.loading = false;
          this.emailReceived = true;
        })
        .catch(() => {
          console.error(error);
          this.wrongEmail = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-card {
  background-color: $gray;
  border-radius: 25px 25px 25px 0px;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    top: 24px;
    min-width: 550px;
    min-height: 490px;
  }
}

.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
  margin: 0;
}

.q-field--filled .q-field__control {
  height: 53px !important;
}
</style>
